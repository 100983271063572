import {
  SmallTooltip,
  Icon,
  Tooltip,
  TextVariant,
  Text,
  TextType,
} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {useCallback, FC, useState, useRef} from 'react';
import {useCopyToClipboard, useHover} from 'usehooks-ts';

interface ContactFieldProps {
  value: string;
  name: string;
  href: string;
  onClick?: () => void;
  truncate?: boolean;
  variant?: TextVariant;
  as?: TextType;
  textClassName?: ClassValue;
  tooltipClassName?: ClassValue;
  showPhoneNumberTooltip?: boolean;
}
const ContactField: FC<ContactFieldProps> = ({
  value,
  name,
  href,
  truncate = false,
  onClick,
  variant = 'primary',
  as = 'body-md',
  textClassName,
  tooltipClassName,
  showPhoneNumberTooltip = true,
}) => {
  const [_clipboardText, copyToClipboard] = useCopyToClipboard();

  const [copied, setCopied] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isHovering = useHover(containerRef);

  const handleCopyClick = useCallback(() => {
    copyToClipboard(value).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  }, [copyToClipboard, value]);

  const renderPhonenumber = () => (
    <Text
      tag='a'
      href={href}
      as={as}
      weight='semibold'
      variant={variant}
      onClick={onClick}
      className={clsx(
        'hover:no-underline',
        truncate ? 'max-w-[225px] truncate' : null,
        variant === 'primary' && 'hover:text-theme-dark-alt',
        textClassName,
      )}>
      {value}
    </Text>
  );

  return (
    <div
      className='flex cursor-pointer items-center space-x-1'
      ref={containerRef}>
      {showPhoneNumberTooltip ? (
        <Tooltip content={value}>{renderPhonenumber()}</Tooltip>
      ) : (
        renderPhonenumber()
      )}

      {isHovering && (
        <SmallTooltip
          label={copied ? 'Copied!' : `Copy ${name}`}
          openOnHover
          className='!left-1/2 -translate-x-1/2'>
          <button
            className='cursor-pointer bg-transparent'
            onClick={handleCopyClick}>
            <Icon
              name={copied ? 'CopyFilled' : 'Copy'}
              className={clsx(
                'flex h-5 w-5',
                variant === 'base' && 'text-neutral-primary',
                tooltipClassName,
              )}
            />
          </button>
        </SmallTooltip>
      )}
    </div>
  );
};

export default ContactField;
