import {useIsSmallDesktop} from '@prescriberpoint/ui';
import {useRouter} from 'next/router';
import {FC, memo} from 'react';
import {NEW_VERSION_DS} from '@/constants/flags';
import {
  PharmacyContextProvider,
  MobileContentProvider,
  useUserAgentContext,
  useFlag,
} from '@/context';
import {useUser} from '@/hooks';
import {UserPanelType} from '@/interfaces/userMenu';
import {PdpLayout, PdpLayoutNew} from '@/layouts';
interface PdpLayoutWrapperProps {
  children: React.ReactNode;
}

const PdpLayoutWrapper: FC<PdpLayoutWrapperProps> = ({children}) => {
  const router = useRouter();
  const {panel} = router.query;

  const {isMobileOrTablet} = useUserAgentContext();
  const isSmallDesktop = useIsSmallDesktop();
  const {logged} = useUser();
  const newVersionDS = useFlag(NEW_VERSION_DS);

  return (
    <PharmacyContextProvider>
      <MobileContentProvider
        userPanel={
          isMobileOrTablet || (!logged && isSmallDesktop)
            ? (panel as UserPanelType)
            : undefined
        }>
        {newVersionDS ? (
          <PdpLayoutNew>{children}</PdpLayoutNew>
        ) : (
          <PdpLayout>{children}</PdpLayout>
        )}
      </MobileContentProvider>
    </PharmacyContextProvider>
  );
};

export default memo(PdpLayoutWrapper);
