import {
  removeAllHTMLElementInStr,
  removeHeaderNumbersAndAnchors,
  removeTableWidthAttribute,
} from '@prescriberpoint/ui';
import DOMPurify from 'isomorphic-dompurify';
import {useMemo} from 'react';
import {useLabel} from '@/queries';
import {formatDosageHTML} from '@/utils';

export const useShowDosageSection = (setId: string) => {
  const {data: product} = useLabel(setId);
  const content = product?.label?.highlights?.dosageAndAdministration;

  const sanitizedText = useMemo(() => {
    if (!content) {
      return '';
    }
    let safeContent = removeHeaderNumbersAndAnchors(
      removeAllHTMLElementInStr(content, 'h1'),
    ).replace(/\s{9}/g, '');

    safeContent = removeTableWidthAttribute(safeContent);

    return DOMPurify.sanitize(formatDosageHTML(safeContent));
  }, [content]);

  return !!sanitizedText;
};
