import {Loader, useIsMobileOrTablet} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import dynamic from 'next/dynamic';
import {FC, FormEvent, useCallback, useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';
import InterstitialModal from '../InterstitialModal';
import {
  CEVENT_BRAND_RSC_SHARED,
  CEVENT_DRUG_SHARED,
  DlvBrandRscShared,
  DlvDrugShared,
  CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE,
  CEVENT_BRAND_RSC_ABANDONMENT,
  DlvBrandRscContinueToWebsite,
  DlvBrandRscAbandonment,
} from '@/constants';
import {SPLIT_USER_COOKIE} from '@/constants/cookies';
import {useRedirectUrl, useDlvDrugInfo, useFirePaywall} from '@/hooks';
import {useCreateShareRequest} from '@/mutations';
import {customEvent, getEmailDomain, proccessSectionId} from '@/utils/gtm';
import {toSnakeCase} from '@/utils/string';

export interface ShareProps {
  id: string;
  resource: string;
  shareLink: string;
  isShareOpen: boolean;
  handleClose: () => void;
  renderUnderButton: boolean;
  section: 'visit' | 'share';
  drugName: string;
  className?: ClassValue;
  resourceParentSection?: string;
  resourceSection?: string;
  onlyContent?: boolean;
}

const MobileShare = dynamic(() => import('./MobileShare'));
const DesktopShare = dynamic(() => import('./DesktopShare'));

const Step1 = dynamic(() => import('./Step1'), {
  loading: () => <Loader />,
});

const Step2 = dynamic(() => import('./Step2'), {
  loading: () => <Loader />,
});

const Step3 = dynamic(() => import('./Step3'), {
  loading: () => <Loader />,
});

const Share: FC<ShareProps> = ({
  id,
  resource,
  shareLink,
  isShareOpen,
  handleClose,
  section,
  renderUnderButton,
  drugName,
  className,
  resourceParentSection,
  resourceSection,
  onlyContent = false,
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const [step, setStep] = useState(1);
  const [sendTo, setSendTo] = useState('');
  const [senderName, setSenderName] = useState('');
  const {mutateAsync: createShareRequest, isSuccess} = useCreateShareRequest();
  const [cookies] = useCookies([SPLIT_USER_COOKIE]);
  const {redirectUrl} = useRedirectUrl(shareLink, {
    utm_source: 'PrescriberPoint',
    utm_medium: `${toSnakeCase(drugName)}_referral`,
  });
  const dlvDrugData = useDlvDrugInfo();
  const firePaywall = useFirePaywall();

  const showInterstitialModal = useMemo(
    () => isShareOpen && step == 1 && section == 'visit',
    [isShareOpen, step, section],
  );

  const handleConfirmLeave = useCallback(() => {
    customEvent<DlvBrandRscContinueToWebsite>(
      CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE,
      {
        drugName: drugName,
        resourceName: resource,
        link: shareLink,
        userKey: cookies[SPLIT_USER_COOKIE],
      },
    );
    handleClose();
  }, [drugName, handleClose, resource, shareLink, cookies]);

  const handleGoBack = useCallback(() => {
    customEvent<DlvBrandRscAbandonment>(CEVENT_BRAND_RSC_ABANDONMENT, {
      drugName: drugName,
      resourceName: resource,
      resourceParentSection: resourceParentSection,
      resourceSection: resourceSection,
      firePaywall: firePaywall,
    });
    handleClose();
  }, [
    firePaywall,
    drugName,
    handleClose,
    resource,
    resourceParentSection,
    resourceSection,
  ]);

  const handleCloseShare = () => {
    handleClose();
    resetData();
  };

  const resetData = useCallback(() => {
    setStep(1);
    setSendTo('');
    setSenderName('');
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = {
        brand: drugName!,
        resource: resource,
        target: sendTo,
        type: 'email',
        link: redirectUrl,
        senderName: senderName,
      };
      await createShareRequest(data);

      if (id === 'summary_share_info') {
        customEvent<DlvDrugShared>(CEVENT_DRUG_SHARED, dlvDrugData);
      } else {
        customEvent<DlvBrandRscShared>(CEVENT_BRAND_RSC_SHARED, {
          drugName: drugName!,
          resourceName: resource,
          emailDomain: getEmailDomain(sendTo),
          shareType: 'email',
          link: redirectUrl,
          resourceSection: proccessSectionId(resourceSection ?? ''),
          resourceParentSection: proccessSectionId(resourceParentSection ?? ''),
        });
      }
      setStep(3);
    } catch (error: any) {
      console.log(error.data);
      setStep(3);
    }
  };

  const renderSwitch = (param: number) => {
    switch (param) {
      case 1:
        return (
          <Step1
            resourceName={resource}
            brandName={drugName}
            setStep={setStep}
            value={sendTo}
            setValue={setSendTo}
          />
        );
      case 2:
        return (
          <Step2
            resourceName={resource}
            handleClose={handleCloseShare}
            value={senderName}
            setValue={setSenderName}
            handleSubmit={handleSubmit}
          />
        );
      case 3:
        return (
          <Step3
            brandName={drugName}
            value={sendTo}
            handleClose={handleCloseShare}
            failed={!isSuccess}
          />
        );
      default:
        return (
          <Step1
            resourceName={resource}
            brandName={drugName}
            setStep={setStep}
            value={sendTo}
            setValue={setSendTo}
          />
        );
    }
  };

  if (showInterstitialModal) {
    return (
      <InterstitialModal
        isOpen
        resourceName={resource}
        brandName={drugName}
        shareLink={shareLink}
        confirmLeave={handleConfirmLeave}
        goBack={handleGoBack}
      />
    );
  }

  if (onlyContent) {
    return <>{renderSwitch(step)}</>;
  }

  return (
    <div
      className={clsx('flex cursor-default', className, {
        'hidden': !isShareOpen,
      })}
      id={id}
      data-testid={id}>
      {isMobileOrTablet ? (
        <MobileShare isOpen={isShareOpen} handleClose={handleCloseShare}>
          {renderSwitch(step)}
        </MobileShare>
      ) : (
        <DesktopShare
          isOpen={isShareOpen}
          handleClose={handleCloseShare}
          renderUnderButton={renderUnderButton}>
          {renderSwitch(step)}
        </DesktopShare>
      )}
    </div>
  );
};

export default Share;
