import {IPageSection} from '@/interfaces';

const PATIENT_EDUCATION_SECTION: IPageSection = {
  title: '{brandName} Patient Education',
  description: {
    standard:
      'Many brands like {brandName} offer an array of print, digital, interactive and care team support services to help your patients enroll and stay on therapy. ',
    enhanced:
      'Many brands offer an array of print, digital, interactive and care team support services to help your patients enroll and stay on therapy. ',
  },
  cta: 'Request resources added',
  sections: [
    {
      sectionId: 'sectionInstructionsForUse',
      title: 'Getting started on {brandName}',
      description: {
        standard:
          'Many brands offer guides and instructions for use to help patients get started on therapies. ',
      },
      standard: true,
      enhanced: false,
      fixedResources: [],
    },
    {
      sectionId: 'sectionPatientToolkit',
      title: 'Patient toolkit',
      description: {
        standard:
          'Additional patient-facing resources to understand and manage their condition as they go about their day-to-day life.',
        enhanced:
          'Other resources brands publish to help support patients and caregivers.',
      },
      standard: true,
      enhanced: true,
      enhancedContentTitle: 'View by indication',
    },
    {
      sectionId: 'sectionOtherResources',
      title: 'Other resources',
      description: {
        standard: 'Other resources brands publish to help support patients.',
        enhanced: 'Other resources brands publish to help support patients.',
      },
      standard: true,
      enhanced: true,
    },
  ],
};

export default PATIENT_EDUCATION_SECTION;
