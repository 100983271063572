import {FC, ReactNode} from 'react';

interface ContentLayoutNewProps {
  children: ReactNode;
}

const PageContentLayoutNew: FC<ContentLayoutNewProps> = ({children}) => (
  <div className='relative mx-auto max-w-[1360px] bg-neutral-white px-6 pb-8 md:px-10 md:pb-12'>
    {children}
  </div>
);

export default PageContentLayoutNew;
