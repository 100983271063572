import {Text, Link, TextType, LinkSize} from '@prescriberpoint/ui';
import {FC, ReactNode} from 'react';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {toSnakeCase} from '@/utils';

export interface FooterCTA {
  label: string;
  callback?: () => void;
  button: string;
  href?: string;
  rightIcon?: () => ReactNode;
  target?: string;
}

interface IAuthFooterProps {
  callToActions: FooterCTA[];
  as?: TextType;
  linkSize?: LinkSize;
}

const AuthFooter: FC<IAuthFooterProps> = ({callToActions, as, linkSize}) => {
  const {isMobileOrTablet} = useUserAgentContext();

  return (
    <div className='flex w-full flex-col space-y-2'>
      {callToActions.map((cta) => (
        <div className='inline items-center' key={cta.label}>
          {cta.label ? (
            <Text
              as={as || isMobileOrTablet ? 'body-sm' : 'body-md'}
              className='mr-1'>
              {cta.label}
            </Text>
          ) : null}
          <Link
            id={toSnakeCase(cta.button)}
            href={cta?.href ?? ''}
            onClick={cta.callback}
            label={cta.button}
            target={cta?.target}
            linkSize={linkSize || isMobileOrTablet ? 'lg' : 'xl'}
            type='underline'
            className='inline'
          />
          {cta.rightIcon ? cta.rightIcon() : null}
        </div>
      ))}
    </div>
  );
};

export default AuthFooter;
