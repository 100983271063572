/**
 * TODO: Create endpoint to get this data from an API
 * Note: This will change in 2024, then again in 2025, 2026, ...
 * The poverty guideline is provided by: https://aspe.hhs.gov/topics/poverty-economic-mobility/poverty-guidelines
 */
export const POVERTY_GUIDELINES = {
  continentalUS: [14580, 19720, 24860, 30000, 35140, 40280, 45420, 50560],
  alaska: [18210, 24640, 31070, 37500, 43930, 50360, 56790, 63220],
  hawaii: [16770, 22680, 28590, 34500, 40410, 46320, 52230, 58140],
};
export type IUsStateGuideline = keyof typeof POVERTY_GUIDELINES;

const FOUNDATION_PROGRAM_NAME = 'Bridge / Foundation Program';

export const FINANCIAL_ASSISTANCE_INFO = {
  title: 'Your patient is eligible for financial assistance',
  info: 'Here are programs that they may be eligible for:',
  programName: FOUNDATION_PROGRAM_NAME,
  recommendation:
    'We recommend enrollment in any program where your patient is eligible.',
};

export const SAVINGS_PROGRAM_INFO = {
  title: 'Your patient may be able to save money on {drugName}',
  info: 'Here are programs that they may be eligible for:',
  recommendation:
    'We recommend enrollment in any program where your patient is eligible.',
  programName: FOUNDATION_PROGRAM_NAME,
};

export const COMMERCIAL_PROGRAM_INFO = {
  title: 'Your patient may be able to save money on {drugName}',
  info: 'Here are programs that they may be eligible for:',
  recommendation:
    'We recommend enrollment in any program where your patient is eligible.',
};
