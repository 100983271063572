import {Link, LinkSize, Text, TextType} from '@prescriberpoint/ui';
import {clsx, ClassValue} from 'clsx';
import {FC} from 'react';
import {useUserAgentContext} from '@/context/UserAgentContext';

interface IAgreeTermsAndCondtitionsAndPrivacyPolicyProps {
  readonly alternativeTextColor?: boolean;
  readonly className?: ClassValue;
  readonly text?: string;
  readonly as?: TextType;
  readonly linkSize?: LinkSize;
}
const AgreeTermsAndCondtitionsAndPrivacyPolicy: FC<
  IAgreeTermsAndCondtitionsAndPrivacyPolicyProps
> = ({alternativeTextColor = false, className, text, as, linkSize}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  return (
    <Text
      as={(as ?? isMobileOrTablet) ? 'body-sm' : 'body-md'}
      className={clsx({'text-white': alternativeTextColor}, className)}>
      {text ?? 'By signing up, I agree to PrescriberPoint’s '}
      <Link
        id='terms_and_conditions'
        target='_blank'
        rel='noopener noreferrer'
        href='https://prescriberpoint.com/terms'
        label='Terms & Conditions'
        linkSize={(linkSize ?? isMobileOrTablet) ? 'lg' : 'xl'}
        type='underline'
        className='inline'
      />
      {' and '}
      <Link
        id='privacy'
        target='_blank'
        rel='noopener noreferrer'
        href='https://prescriberpoint.com/privacy'
        label='Privacy Policy'
        linkSize={(linkSize ?? isMobileOrTablet) ? 'lg' : 'xl'}
        type='underline'
        className='inline'
      />
    </Text>
  );
};

export default AgreeTermsAndCondtitionsAndPrivacyPolicy;
