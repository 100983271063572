import {Button} from '@prescriberpoint/ui';
import {ClassValue} from 'clsx';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {CEVENT_DDI_CHECK, DlvDDICheck} from '@/constants';
import {SHOW_NEW_DDI} from '@/constants/flags';
import {useDrugInteractionsContext, useFlag} from '@/context';
import {useCurrentSlug, useDlvDrugInfo} from '@/hooks';
import {customEvent} from '@/utils/gtm';

interface IDDIButtonProps {
  id: string;
  className?: ClassValue;
  variant?: 'primary' | 'secondary';
  withIcon?: boolean;
}

const DDIButton: FC<IDDIButtonProps> = (props) => {
  const {slug} = useCurrentSlug();
  const {setIsOpen} = useDrugInteractionsContext();
  const {drugName, drugSpecialties, pharmaCompany} = useDlvDrugInfo();
  const showNewDDI = useFlag(SHOW_NEW_DDI);
  const router = useRouter();

  const handleClick = () => {
    if (showNewDDI) {
      //Go to the interaction's page
      router.push('/therapies/' + slug + '/interactions');
    } else {
      //Show modal
      setIsOpen(true);
    }
    customEvent<DlvDDICheck>(CEVENT_DDI_CHECK, {
      drugName,
      pharmaCompany,
      drugSpecialties,
    });
  };

  return (
    <Button
      id={props.id}
      size='lg'
      variant={props.variant}
      stretched
      onClick={handleClick}
      className={props.className}
      iconLeft={props.withIcon ? 'InteractionsNegative' : undefined}>
      Check Drug Interactions
    </Button>
  );
};

export default DDIButton;
