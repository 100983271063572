import {Icon, Text} from '@prescriberpoint/ui';
import {FC} from 'react';

interface ContactFieldMobileProps {
  href: string;
  iconName: string;
  label: string;
  onClick: () => void;
}
const ContactFieldMobile: FC<ContactFieldMobileProps> = ({
  href,
  iconName,
  label,
  onClick,
}) => (
  <a
    className='flex flex-col items-center justify-center space-y-1 p-1 no-underline'
    href={href}
    onClick={onClick}>
    <Icon name={iconName} className='flex h-5 w-5' />
    <Text as='body-xs' weight='bold'>
      {label}
    </Text>
  </a>
);

export default ContactFieldMobile;
