import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC, useEffect, useState} from 'react';
import Bottom from './Bottom';
import {CustomNavbar as Navbar} from '@/components';
import SidebarPivotMenu from '@/components/SidebarPivotMenu';
import {NAVBAR_ID} from '@/constants';
import {TERMS_AND_CONDITIONS} from '@/constants/flags';
import {useFlag} from '@/context';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useCurrentSlug, useIsPDP, useRenderInIFrame} from '@/hooks';
import useIsPDPSubpage from '@/hooks/useIsPDPSubpage';
import {useCurrentPerson, useLabel} from '@/queries';

const Disclaimer = dynamic(() => import('@/components/Disclaimer'), {
  ssr: false,
});

const TermsAndConditions = dynamic(
  () => import('@/modules/HomePage/TermsAndConditions'),
  {
    ssr: false,
  },
);

interface PdpLayoutNewProps {
  children: React.ReactNode;
}

const Content: FC<PdpLayoutNewProps> = ({children}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const isPDP = useIsPDP();
  const isPDPSubpage = useIsPDPSubpage();
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const [isOpenSidebarPivotMenu, setIsOpenSidebarPivotMenu] = useState(false);

  const showSidebar = (isPDP || isPDPSubpage) && setId && product;

  const termsAndConditionsEnabled = useFlag(TERMS_AND_CONDITIONS);
  const {data: user} = useCurrentPerson();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  useEffect(() => {
    if (termsAndConditionsEnabled && user)
      setShowTermsAndConditions(user.shouldNeedAcceptTsCs);
  }, [user, termsAndConditionsEnabled]);

  const pdpIsEmbedded = useRenderInIFrame();

  const closeTermsAndConditions = () => {
    setShowTermsAndConditions(false);
  };

  return (
    <div className='flex min-h-screen flex-col bg-neutral-white'>
      {showTermsAndConditions ? (
        <TermsAndConditions handleClose={closeTermsAndConditions} />
      ) : null}
      {!pdpIsEmbedded ? (
        <div
          id={NAVBAR_ID}
          className={clsx([
            'relative sticky top-0 z-max w-full',
            isMobileOrTablet ? 'z-50' : 'z-max',
          ])}>
          <Disclaimer />
          <Navbar
            isSidebarOpen={isOpenSidebarPivotMenu}
            setSidebarOpen={(value: boolean) =>
              setIsOpenSidebarPivotMenu(value)
            }
          />
        </div>
      ) : null}

      <div className='flex flex-1 flex-col lg:flex-row'>
        {showSidebar ? (
          <SidebarPivotMenu isOpen={isOpenSidebarPivotMenu} />
        ) : null}
        <div id='layout-content' className='flex flex-1 flex-col'>
          <div className='min-h-page-content flex-1'>{children}</div>
          <Bottom />
        </div>
      </div>
    </div>
  );
};

export default Content;
