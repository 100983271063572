import {getSectionResources, useEnhanced} from '../useEnhanced';
import {useShowEnhancedSection} from './useShowEnhancedSection';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import PATIENT_EDUCATION_SECTION from '@/data/patientEducation';

export const useShowPESection = (setId: string) => {
  const {resources} = useEnhanced(setId, SECTION_IDS.patientEducation);
  const sectionId = PATIENT_EDUCATION_SECTION.sections[0].sectionId;
  const sectionResources = getSectionResources(resources, sectionId);
  const hasResources = sectionResources?.length > 0;

  const showPEFromEnhanced = useShowEnhancedSection(
    SECTION_IDS.patientEducation,
    FLAGS.PATIENT_EDUCATION_SECTION,
  );

  return showPEFromEnhanced && hasResources;
};
