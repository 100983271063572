import {useRouter} from 'next/router';
import {FC} from 'react';
import Share, {IShareData} from '@/components/NewShare';
import {CEVENT_DRUG_SHARED, DlvDrugShared} from '@/constants';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {useCurrentDrug, useDlvDrugInfo} from '@/hooks';
import {useCreateShareRequest} from '@/mutations';
import {customEvent} from '@/utils/gtm';

export interface IShareStep {
  onClose: () => void;
  onSubmit?: () => void;
  shareSubmitted: boolean;
}

const ShareStep: FC<IShareStep> = ({onClose, onSubmit, shareSubmitted}) => {
  const {drugName} = useCurrentDrug();
  const router = useRouter();
  const {mutateAsync: createShareRequest} = useCreateShareRequest();
  const dlvDrugData = useDlvDrugInfo();

  const handleSubmit = async (shareData: IShareData) => {
    const {email, fromName} = shareData;
    const shareUrl = `${PROTOCOL_HOSTNAME}${router.asPath}`;

    const data = {
      brand: drugName,
      resource: shareUrl,
      target: email,
      type: 'email',
      link: shareUrl,
      senderName: fromName,
    };

    await createShareRequest(data);

    customEvent<DlvDrugShared>(CEVENT_DRUG_SHARED, dlvDrugData);
    onSubmit?.();
  };

  return (
    <Share
      shareTitle={`Share ${drugName}`}
      shareMessage={`Do you want to send this ${drugName} page to your coworker? Add their email and we will send it to their inbox`}
      confirmationTitle='Page Shared!'
      confirmationMessage={`The ${drugName} product information page has been shared!`}
      showConfirmation={shareSubmitted}
      onCloseClick={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default ShareStep;
