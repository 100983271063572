import {Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import Image from 'next/image';
import {FC} from 'react';
import ContactField from './ContactField';
import ContactFieldMobile from './ContactFieldMobile';
import {CEVENT_SALES_REP_CONTACT, DlvRepContact} from '@/constants';
import {useUserAgentContext} from '@/context';
import {useDlvDrugInfo} from '@/hooks';
import {customEvent} from '@/utils/gtm';

interface RepContactCardProps {
  name: string;
  role: null | string;
  phoneNumber: null | string;
  email: null | string;
  picture?: string | null;
  nameInitials: string;
  expanded?: boolean;
}

const RepContactCard: FC<RepContactCardProps> = ({
  name,
  role,
  phoneNumber,
  email,
  picture,
  expanded = false,
  nameInitials,
}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const dlvData = useDlvDrugInfo();

  const handleClick = (contactType: 'email' | 'phone') => {
    customEvent<DlvRepContact>(CEVENT_SALES_REP_CONTACT, {
      ...dlvData,
      repContactType: contactType,
      repName: name,
    });
  };

  return (
    <div
      className={clsx(
        'flex items-center space-x-6 bg-white p-4',
        expanded || isMobileOrTablet
          ? 'w-full'
          : 'w-[410px] md:rounded-md md:shadow-sm',
      )}>
      <div className='relative flex h-full w-20 items-center justify-center'>
        {picture ? (
          <Image
            width={80}
            height={80}
            src={picture}
            alt='rep_profile_image'
            className='rounded-full'
          />
        ) : (
          <div className='flex h-20 w-20 items-center justify-center rounded-full bg-neutral-tertiary font-houschka-rounded text-2xl font-bold text-white'>
            {nameInitials}
          </div>
        )}
      </div>
      <div className='flex flex-1 flex-col space-y-2'>
        <div className='flex flex-col'>
          <Text as='title-lg'>{name}</Text>
          {role ? <Text as='body-md'>{role}</Text> : null}
        </div>
        <div className='flex'>
          {isMobileOrTablet ? (
            <div className='flex flex-wrap space-x-6'>
              {phoneNumber ? (
                <ContactFieldMobile
                  href={`tel:${phoneNumber}`}
                  iconName='Call'
                  label='Call'
                  onClick={() => handleClick('phone')}
                />
              ) : null}
              {email ? (
                <ContactFieldMobile
                  href={`mailto:${email}`}
                  iconName='Mail'
                  label='Email'
                  onClick={() => handleClick('email')}
                />
              ) : null}
            </div>
          ) : (
            <div className={clsx('flex', expanded ? 'space-x-2' : 'flex-col')}>
              {phoneNumber ? (
                <ContactField
                  href={`tel:${phoneNumber}`}
                  value={phoneNumber}
                  name='number'
                  onClick={() => handleClick('phone')}
                />
              ) : null}
              {email ? (
                <ContactField
                  href={`mailto:${email}`}
                  value={email}
                  name='email'
                  truncate={!expanded}
                  onClick={() => handleClick('email')}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RepContactCard;
