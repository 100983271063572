import {useEnhanced} from '../useEnhanced';
import {useShowEnhancedSection} from './useShowEnhancedSection';
import * as FLAGS from '@/constants/flags';
import {FULL_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';

export const useShowFASection = (setId: string) => {
  const {enhancedContent} = useEnhanced(setId);
  const {copaySavingsCardProgram, patientAssistanceProgram} =
    enhancedContent ?? {};
  const showFAS = useShowEnhancedSection(
    FULL_SECTION_IDS.financialAssistance,
    FLAGS.FINANCIAL_ASSISTANCE_SUPPORT_SECTION,
  );
  const showNewFA = useFlag(FLAGS.AFFORDABILITY_ON_PDP);
  // if there are no programs available, show the generic financial assistance support section
  const programsAvailable = copaySavingsCardProgram || patientAssistanceProgram;
  const showFinancialAssistanceSection =
    (showNewFA && programsAvailable) || showFAS;

  return !!showFinancialAssistanceSection;
};
