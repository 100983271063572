import {Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, Children} from 'react';
import {v4 as uuidv4} from 'uuid';

export interface IStepFormItemProps {
  stepNumber: number;
  disabled?: boolean;
  disabledLabel?: string;
  children: React.ReactNode;
}

const StepFormItem: FC<IStepFormItemProps> = ({
  stepNumber,
  disabled = false,
  disabledLabel,
  children,
}) => {
  // count children to show a placeholder for each one (assuming all children will be inputs)
  const childrenCount = Children.toArray(children)?.length;
  return (
    <div className='flex w-full space-x-4 px-2 pt-4'>
      <div className='flex flex-col items-center'>
        <div
          className={clsx(
            'flex h-8 w-8 items-center rounded-full p-1',
            disabled ? 'bg-neutral-tertiary' : 'bg-success',
          )}>
          <span
            data-testid={`form-step-number-${stepNumber}`}
            className='flex h-6 w-6 items-end justify-center text-center font-houschka-pro text-xl font-bold leading-[100%] text-white'>
            {stepNumber}
          </span>
        </div>
        <div className='w-[1px] flex-1 bg-neutral-light' />
      </div>
      <div className='flex w-full flex-col space-y-2 pb-4'>
        {disabled ? (
          <div>
            {disabledLabel ? (
              <div className='mb-1 flex items-center space-x-0.5'>
                <Text as='body-sm' weight='bold' casing='title'>
                  {disabledLabel}
                </Text>
                <span className='text-xs font-normal text-error'>*</span>
              </div>
            ) : null}
            <div className='flex flex-col space-y-2'>
              {[...Array(childrenCount)].map((_, idx) => (
                <div
                  key={`assign-resource-form-item-placeholder-${uuidv4()}`}
                  className={clsx(
                    idx === 0 ? 'h-12' : 'h-18',
                    'w-full bg-neutral-lighter',
                  )}
                />
              ))}
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default StepFormItem;
