import {ButtonIcon, Modal} from '@prescriberpoint/ui';
import {Ellipsis} from 'lucide-react';
import {FC, useMemo, useState} from 'react';
import BaseStep from './BaseStep';
import FavoriteStep from './FavoriteStep';
import RemsStep from './RemsStep';
import ShareStep from './ShareStep';
import {useBookmarkPDP, useCurrentDrug} from '@/hooks';

export type Step = {
  name: string;
  value: number;
};

export const REMS_STEP_NUMBER = 1;
export const FAVORITE_STEP_NUMBER_1 = 2;
export const FAVORITE_STEP_NUMBER_2 = 3;
export const SHARE_STEP_NUMBER = 4;

export const STEPS: Step[] = [
  {name: 'base', value: 0},
  {name: 'Rems Info', value: REMS_STEP_NUMBER},
  {name: 'Bookmark', value: FAVORITE_STEP_NUMBER_1},
  {name: 'Share', value: SHARE_STEP_NUMBER},
];

interface IMenuExtraDataProps {
  showRems: boolean;
}

const MenuExtraData: FC<IMenuExtraDataProps> = ({showRems}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [shareSubmitted, setShareSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const currentDrug = useCurrentDrug();
  const {closeModal} = useBookmarkPDP(currentDrug);

  const showCloseButton = useMemo(
    () => shareSubmitted || [FAVORITE_STEP_NUMBER_2, 0].includes(step),
    [step, shareSubmitted],
  );

  const handleOnClick = (stepNumber: number) => {
    setStep(stepNumber);
  };

  const handleOnClose = () => {
    setModalOpen(false);
    step === FAVORITE_STEP_NUMBER_2 && closeModal();
    setShareSubmitted(false);
    setStep(0);
  };

  const handleBack = () => {
    setStep(0);
    setShareSubmitted(false);
  };

  const handleSubmitShare = () => {
    setShareSubmitted(true);
  };

  return (
    <>
      <ButtonIcon
        id='open_hero_icons'
        size='sm'
        className='shrink-0'
        Icon={Ellipsis}
        variant='default'
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={modalOpen}
        closeModal={handleOnClose}
        showCloseButton={showCloseButton}
        position='side'
        onGoBack={handleBack}
        bodyClassName='py-0'
        showGoBack={!showCloseButton}
        showConfirm={false}
        showCancel={false}>
        {step === 0 ? (
          <BaseStep showRems={showRems} steps={STEPS} onClick={handleOnClick} />
        ) : null}
        {step === REMS_STEP_NUMBER && showRems ? <RemsStep /> : null}
        {step === FAVORITE_STEP_NUMBER_1 || step === FAVORITE_STEP_NUMBER_2 ? (
          <FavoriteStep
            isOpen={modalOpen}
            step={step}
            onChangeStep={(value: number) => setStep(value)}
            onClose={handleOnClose}
          />
        ) : null}
        {step === SHARE_STEP_NUMBER ? (
          <ShareStep
            onClose={handleOnClose}
            onSubmit={handleSubmitShare}
            shareSubmitted={shareSubmitted}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default MenuExtraData;
