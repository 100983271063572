import {customEvent, Tooltip, ButtonIcon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {Share2} from 'lucide-react';
import {useRouter} from 'next/router';
import {FC, useCallback, useState} from 'react';
import {IShareData} from '../NewShare';
import DesktopShare from '../NewShare/DesktopShare';
import {Bookmark, Share} from '@/components';
import {
  CEVENT_DRUG_SHARED,
  CEVENT_SHARE_BRAND_PAGE,
  DlvDrugShared,
} from '@/constants';
import {NEW_VERSION_DS} from '@/constants/flags';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {useFlag} from '@/context';
import {useDlvDrugInfo} from '@/hooks';
import {useCreateShareRequest} from '@/mutations';

interface HeroIconsProps {
  drugName: string;
  shareClassName?: string;
}

const HeroIcons: FC<HeroIconsProps> = ({shareClassName, drugName}) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [shareSubmitted, setShareSubmitted] = useState(false);
  const router = useRouter();
  const {mutateAsync: createShareRequest} = useCreateShareRequest();
  const dlvDrugData = useDlvDrugInfo();
  const showNewDesign = useFlag(NEW_VERSION_DS);

  const handleShareClick = useCallback(() => {
    customEvent(CEVENT_SHARE_BRAND_PAGE, {
      brand: drugName,
    });
    setIsShareOpen(true);
  }, [drugName]);

  const handleModalClose = () => {
    setIsShareOpen(false);
    setShareSubmitted(false);
  };

  const handleSubmit = async (shareData: IShareData) => {
    const {email, fromName} = shareData;
    const shareUrl = `${PROTOCOL_HOSTNAME}${router.asPath}`;

    const data = {
      brand: drugName,
      resource: shareUrl,
      target: email,
      type: 'email',
      link: shareUrl,
      senderName: fromName,
    };

    await createShareRequest(data);

    customEvent<DlvDrugShared>(CEVENT_DRUG_SHARED, dlvDrugData);
    setShareSubmitted(true);
  };

  return (
    <div className='relative'>
      <Bookmark id='summary_like' data-testId='summary_like' />
      <Tooltip
        className='!left-auto right-0 !translate-x-0'
        content='Share'
        position={showNewDesign ? 'top' : 'bottom'}>
        <ButtonIcon
          id='summary_share'
          data-testid='summary_share'
          Icon={Share2}
          onClick={handleShareClick}
          variant='default'
          size='md'
        />
      </Tooltip>
      <div
        className={clsx(
          'absolute right-0 top-11 sm:w-[320px]',
          shareClassName,
        )}>
        {showNewDesign ? (
          <DesktopShare
            isModalOpen={isShareOpen}
            onModalClose={handleModalClose}
            shareTitle={`Share ${drugName}`}
            shareMessage={`Do you want to send this ${drugName} page to your coworker? Add their email and we will send it to their inbox`}
            confirmationTitle='Page Shared!'
            confirmationMessage={`The ${drugName} product information page has been shared!`}
            onCloseClick={handleModalClose}
            onSubmit={handleSubmit}
            showConfirmation={shareSubmitted}
          />
        ) : (
          <Share
            id='summary_share_info'
            isShareOpen={isShareOpen}
            handleClose={() => setIsShareOpen(false)}
            renderUnderButton
            shareLink={`${PROTOCOL_HOSTNAME}${router.asPath}`}
            resource={drugName}
            drugName={drugName}
            section='share'
          />
        )}
      </div>
    </div>
  );
};

export default HeroIcons;
