import clsx from 'clsx';
import {FC} from 'react';
import {Footer} from '@/components';
import {
  NEW_HEADER,
  PPT_AI_COMPONENT,
  HIDE_CHATBOT_FOR_NO_ENHANCED,
} from '@/constants/flags';
import {useFlag, useUserAgentContext} from '@/context';
import {useCurrentSlug, useRenderInIFrame, useEnhanced} from '@/hooks';
import {useIsPfizerDrug} from '@/hooks/useIsPfizerDrug';

const Bottom: FC = () => {
  const {isMobileOrTablet} = useUserAgentContext();
  const {setId} = useCurrentSlug();
  const {newHeaderAvailable, disableAi, isEnhanced} = useEnhanced(setId);
  const showAIPPT = useFlag(PPT_AI_COMPONENT) && !disableAi;
  const hideChatbotForNoEnhanced = useFlag(HIDE_CHATBOT_FOR_NO_ENHANCED);
  const showNewHeader = useFlag(NEW_HEADER) && newHeaderAvailable;
  const isPfizerDrug = useIsPfizerDrug();

  const showButtonAI =
    !isPfizerDrug && showAIPPT && (isEnhanced || !hideChatbotForNoEnhanced);

  const pdpIsEmbedded = useRenderInIFrame();

  if (pdpIsEmbedded) return null;

  return (
    <div className='w-full'>
      <Footer />
      <div
        className={clsx('bg-neutral-dark md:hidden', {
          'min-h-12': showNewHeader && showButtonAI,
          'min-h-[87px]': showButtonAI && isMobileOrTablet && !showNewHeader,
        })}
      />
    </div>
  );
};

export default Bottom;
