import {useMemo} from 'react';
import {useCurrentSlug} from '../useCurrentSlug';
import {useShowDosageSection} from './useShowDosageSection';
import {useShowFASection} from './useShowFASection';
import {useShowPASection} from './useShowPASection';
import {useShowPESection} from './useShowPESection';

// TODO we need to continue this,
// to have in one place the status of the sections

export function useSectionsStatus() {
  const {setId} = useCurrentSlug();

  const showPA = useShowPASection(setId);
  const showPE = useShowPESection(setId);
  const showFA = useShowFASection(setId);

  const showDosage = useShowDosageSection(setId);

  const sections = useMemo(
    () => ({
      summary: showDosage, // dosages and administration
      financialAssistance: showFA, // financial assistance
      priorAuthorization: showPA, // prior authorization
      patientEducation: showPE, // patient education
    }),
    [showFA, showPA, showPE, showDosage],
  );

  return sections;
}
