import {ToastNotification} from '@prescriberpoint/ui';
import {useAuthPath} from '@/hooks';

export const NotificationContent = () => {
  const {signupPath} = useAuthPath();

  return (
    <ToastNotification
      text='Saved to bookmarks'
      button={{
        id: 'view_all',
        text: 'View All',
        hrefKey: signupPath,
      }}
    />
  );
};
