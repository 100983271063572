import {Transition} from '@headlessui/react';
import clsx from 'clsx';
import {FC, ReactNode, useRef} from 'react';
import {useOnClickOutside} from 'usehooks-ts';

interface ITooltipProps {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  renderUnderButton: boolean;
}

const Tooltip: FC<ITooltipProps> = ({
  children,
  isOpen,
  handleClose,
  renderUnderButton,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, handleClose);

  return (
    <div ref={containerRef}>
      {isOpen ? (
        <Transition
          as='div'
          show={isOpen}
          className={clsx([
            'absolute right-0 z-max w-full',
            !renderUnderButton &&
              '-top-2 origin-bottom-right -translate-y-full',
          ])}
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'>
          {children}
        </Transition>
      ) : null}
    </div>
  );
};

export default Tooltip;
