export {default as MainLayout} from './MainLayout';
export {default as PdpLayout} from './PdpLayout';
export {default as PdpLayoutNew} from './PdpLayoutNew';
export {default as PdpLayoutWrapper} from './PdpLayoutWrapper';
export {default as RepAuthLayout} from './RepAuthLayout';
export {
  default as PageContentLayout,
  PageContentLayoutNew,
} from './PageContentLayout';
export {default as PageWithBreadcrumbLayout} from './PageWithBreadcrumbLayout';
