import {
  DropdownOption,
  Button,
  IButtonProps,
  Text,
  ProfilePhotoUpload,
  TextInputSize,
  FormInputType,
} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, useState, FormEvent, ReactNode, useCallback} from 'react';
import GenericInput from './GenericInputForm';
import {toSnakeCase} from '@/utils/string';

export interface IFormInput {
  id: string;
  type: FormInputType;
  name?: string;
  label: string;
  required: boolean;
  inputSize?: TextInputSize;
  placeholder?: string;
  errorMessage: React.ReactNode;
  errorComponent?: (v: string, isActive?: boolean) => React.ReactNode;
  validateFn: (v: string) => boolean;
  options?: DropdownOption[];
  defaultValue?: string;
  disabled?: boolean;
  inputBindValue?: string;
  labelClassName?: ClassValue;
  containerClassName?: ClassValue;
  checked?: boolean;
  isPhotoUpload?: boolean;
  className?: ClassValue;
}

export interface ICTAForm {
  submitText?: string;
  isSubmittingText?: string;
  inputs: Array<IFormInput>;
  cancelText?: string;
}

interface CTAFormProps {
  form: ICTAForm;
  onSubmit: (data: any) => Promise<any>;
  onCancel?: () => void;
  submitError?: boolean;
  submitErrorMsg?: string;
  submitSuccess?: boolean;
  submitSuccessMsg?: string;
  infoText?: string | ReactNode;
  isLoading?: boolean;
  className?: ClassValue;
  submitButtonSize?: IButtonProps['size'];
  submitButtonClassName?: ClassValue;
  submitButtonVariant?: IButtonProps['variant'];
  formIsValid?: boolean;
  disableSubmit?: boolean;
}

function initializeFormValues(inputs: IFormInput[]) {
  const hash = new Map();
  inputs.forEach((formInput) => {
    const value = formInput.defaultValue ?? '';
    hash.set(formInput.id, {
      value: value,
      valid: formInput.validateFn(value),
      required: formInput.required,
    });
  });
  return hash;
}

function initializeFormData(inputs: IFormInput[]) {
  let fd = {};
  inputs.forEach((formInput) => {
    const value = formInput.defaultValue ?? '';
    fd = {
      ...fd,
      [formInput.id]: value,
    };
  });
  return fd;
}

const CTAForm: FC<CTAFormProps> = ({
  form,
  onSubmit,
  submitError,
  onCancel,
  infoText,
  submitSuccess,
  submitSuccessMsg = 'Successfully sent',
  submitErrorMsg = 'Something happened, try submitting the form again',
  isLoading,
  className,
  submitButtonSize,
  submitButtonClassName,
  submitButtonVariant = 'primary',
  formIsValid = false,
  disableSubmit = false,
}) => {
  const [formValid, setFormValid] = useState(formIsValid);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(
    initializeFormValues(form.inputs),
  );
  const [formData, setFormData] = useState(initializeFormData(form.inputs));
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPhoto, setSelectedPhoto] = useState<File>();
  const [showEditor, setShowEditor] = useState(false);

  // This is to keep track of all the form values and its validity
  const updateFormValue = useCallback(
    (id: string, value: string, valid: boolean, required?: boolean) => {
      const tempMap = new Map(formValues);
      tempMap.set(id, {value, valid, required});
      setFormValues(tempMap);
      setFormData((prev) => ({...prev, [id]: value}));
      setFormValid(
        Array.from(tempMap.values()).every(
          (item) =>
            item.valid || (item.required === false && item.value === ''),
        ),
      );
    },
    [formValues],
  );

  const handleImgChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileUploaded = event.target.files?.[0];
      setSelectedPhoto(fileUploaded);
      setShowEditor(true);
    },
    [],
  );

  const handleImgUpload = useCallback(
    (imgBlob: string, formInput?: IFormInput) => {
      setShowEditor(false);
      if (formInput) {
        updateFormValue(
          formInput.id,
          imgBlob,
          formInput.validateFn(imgBlob),
          formInput.required,
        );
      }
    },
    [updateFormValue],
  );

  // All the logic and submitting error should be handled outside
  const onSubmitForm = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!formValid) {
        return;
      }
      try {
        setErrorMessage('');
        setIsSubmitting(true);
        if (onSubmit) {
          await onSubmit(formData);
        }
      } catch (e) {
        setErrorMessage(submitErrorMsg);
      } finally {
        setIsSubmitting(false);
      }
    },
    [formValid, formData, onSubmit, submitErrorMsg],
  );

  return (
    <form
      className={clsx('flex w-full flex-col gap-4', className)}
      onSubmit={onSubmitForm}>
      {form.inputs.map((formInput) =>
        formInput?.isPhotoUpload ? (
          <ProfilePhotoUpload
            key={`forminput-${formInput.id}`}
            selectedPhoto={selectedPhoto}
            handleChange={handleImgChange}
            onUpload={handleImgUpload}
            showEditor={showEditor}
            handleBack={() => setShowEditor(false)}
            formInput={formInput}
          />
        ) : (
          <GenericInput
            key={`forminput-${formInput.id}`}
            formInput={formInput}
            disabled={isSubmitting || isLoading || !!formInput.disabled}
            setFormValue={updateFormValue}
          />
        ),
      )}
      {infoText &&
        (typeof infoText === 'string' ? (
          <Text as='body-xs' weight='bold'>
            {infoText}
          </Text>
        ) : (
          infoText
        ))}
      <div className='flex space-x-2'>
        {form.cancelText && (
          <Button
            onClick={onCancel}
            stretched
            testId='cancel-btn'
            id={`${toSnakeCase(form.cancelText || 'cta_form_cancel')}`}>
            {form.cancelText}
          </Button>
        )}
        <Button
          stretched
          className={submitButtonClassName}
          variant={submitButtonVariant}
          type='submit'
          size={submitButtonSize}
          testId='submit-btn'
          disabled={disableSubmit || !formValid || isSubmitting || isLoading}
          id={`${toSnakeCase(form.submitText ?? 'cta_form_submit')}`}>
          {isSubmitting || isLoading
            ? (form.isSubmittingText ?? '')
            : (form.submitText ?? '')}
        </Button>
      </div>
      {submitError || errorMessage ? (
        <Text
          as='body-xs'
          weight='bold'
          variant='error'
          tag='div'
          className='!text-left'>
          {submitErrorMsg || errorMessage}
        </Text>
      ) : null}
      {submitSuccess ? (
        <Text className='text-success'>{submitSuccessMsg}</Text>
      ) : null}
    </form>
  );
};

export default CTAForm;
