import {useMemo} from 'react';
import {useCurrentSlug} from '../useCurrentSlug';
import {useEnhanced} from '../useEnhanced';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';

export function useShowEnhancedSection(
  section_id: string,
  flag_name: string,
  checkAvailableResources: boolean = true,
) {
  const {setId} = useCurrentSlug();
  const {isEnhanced, disableBrandFaq} = useEnhanced(setId);
  const {resources} = useEnhanced(setId, section_id);

  const availableResources = useMemo(
    () =>
      checkAvailableResources
        ? resources.filter(
            (resource) =>
              !resource.tags.find(
                (tag) =>
                  tag.id === 'contentNotAvailable' ||
                  tag.id === 'sectionOtherResources',
              ),
          )
        : resources,
    [resources, checkAvailableResources],
  );

  const showSection = useMemo(() => {
    if (section_id === SECTION_IDS.peopleAlsoAsk) return !disableBrandFaq;

    return true;
  }, [disableBrandFaq, section_id]);

  return (
    useFlag(flag_name) &&
    isEnhanced &&
    (checkAvailableResources ? availableResources?.length > 0 : showSection)
  );
}
