import {Portal} from '@headlessui/react';
import {ToastContainer, ToastOptions} from 'react-toastify';

export const DEFAULT_TOAST_OPTIONS: ToastOptions = {
  position: 'bottom-center',
  className: '!p-0 [&>div]:!p-0 !min-h-12 bg-red-500',
  closeButton: false,
  hideProgressBar: true,
  autoClose: false,
  closeOnClick: false,
};

const ToastContainerHolder = () => (
  <Portal>
    <ToastContainer className='md:!w-fit' />
  </Portal>
);

export default ToastContainerHolder;
