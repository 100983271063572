import {useShowEnhancedSection} from './useShowEnhancedSection';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useRecentPaForms, useRecentStateUniformForms} from '@/queries';

export const useShowPASection = (setId: string) => {
  const {data: recentStateUniformForms} = useRecentStateUniformForms();
  const {data: recentDocs} = useRecentPaForms(setId);
  const showPAFromEnhanced = useShowEnhancedSection(
    SECTION_IDS.priorAuthorization,
    FLAGS.PRIOR_AUTHORIZATION_SECTION,
  );

  const recentStateUniformFormsLength =
    recentStateUniformForms?.documents?.length ?? 0;
  const recentDocsLength = recentDocs?.documents?.length ?? 0;

  return (
    showPAFromEnhanced ||
    recentStateUniformFormsLength > 0 ||
    recentDocsLength > 0
  );
};
