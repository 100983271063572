import {useMemo} from 'react';
import {OTHER_SECTION_IDS, SECTION_IDS} from '@/constants/sectionIDs';
import {IContentSection} from '@/interfaces';
import {EnhancedContentLinkDto} from '@/models';
import {useEnhancedContent} from '@/queries/useEnhancedContent';
import {useLabel} from '@/queries/useLabel';

export const PARENT_SECTION_ID_MAP = {
  [OTHER_SECTION_IDS.msl]: 'parentSectionMedicalScienceLiaison',
  [OTHER_SECTION_IDS.reportAdverseEvent]: 'parentSectionReportAdverseEvent',
  [SECTION_IDS.priorAuthorization]: 'parentSectionPriorAuthorization',
  [SECTION_IDS.financialAssistance]: 'parentSectionFinancialAssistanceSupport',
  [SECTION_IDS.pubMed]: 'parentSectionPubMed',
  [SECTION_IDS.patientEducation]: 'parentSectionPatientEducation',
  [SECTION_IDS.requestSamples]: 'parentSectionRequestSamples',
};

export function getSectionResources(
  resources: EnhancedContentLinkDto[],
  sectionId: string,
) {
  return resources.filter(({tags}) =>
    tags.find(({name, id}) => name === 'Section' && id === sectionId),
  );
}

export function getAllSectionResources(
  resources: EnhancedContentLinkDto[],
  section: IContentSection,
) {
  const fetchedResources = getSectionResources(resources, section.sectionId);
  const fixedResources = (
    section.fixedResources?.map(
      (fixedTitle) =>
        fetchedResources.find(
          ({title}) =>
            fixedTitle.trim().toLowerCase() === title.trim().toLowerCase(),
        ) ?? {
          title: fixedTitle,
          href: '',
          tags: [],
        },
    ) || []
  ).filter(({href}) => href);

  return fixedResources.concat(
    fetchedResources.filter(
      ({title}) =>
        !section.fixedResources
          ?.map((e) => e.trim().toLocaleLowerCase())
          .includes(title.trim().toLocaleLowerCase()),
    ),
  );
}

export function getSection(
  resources: EnhancedContentLinkDto[],
  sectionId: string,
) {
  return resources.find(({tags}) =>
    tags.find(({name, id}) => name === 'Section' && id === sectionId),
  );
}

export function useEnhanced(setId: string, parentSectionId?: string) {
  const {isPaused, data, isFetching} = useEnhancedContent(setId, {
    enabled: !!setId,
  });
  const {data: product} = useLabel(setId);

  const enhancedContent = data?.enhancedContent;

  const disableAi = data?.enhancedContent?.disableBrandAi ?? false;
  const samplesAvailable = data?.enhancedContent?.samplesAvailable ?? false;
  const disableBrandFaq = data?.enhancedContent?.disableBrandFaq ?? false;
  const isCoverageEnabled = data?.enhancedContent?.coverageEnabled ?? true;
  const newHeaderAvailable = data?.enhancedContent?.atfRefreshLive ?? false;
  const disableMostRecentPaFormsSection =
    data?.enhancedContent?.disableMostRecentPaFormsSection ?? false;

  const resources = useMemo(() => {
    if (enhancedContent && parentSectionId && !isPaused) {
      return enhancedContent.links.filter(
        ({tags, href}) =>
          tags.find(
            ({name, id}) =>
              name === 'ParentSection' &&
              id === PARENT_SECTION_ID_MAP[parentSectionId],
          ) && href,
      );
    }
    return [];
  }, [enhancedContent, parentSectionId, isPaused]);

  return {
    isEnhanced: !!data?.enhancedContent,
    enhancedContent,
    resources,
    isFetching,
    disableAi,
    samplesAvailable,
    isCoverageEnabled,
    disableBrandFaq,
    newHeaderAvailable,
    disableMostRecentPaFormsSection,
  };
}
