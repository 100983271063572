import {Button, FormInput, Text} from '@prescriberpoint/ui';
import {FC, useMemo, useState} from 'react';
import Icon from '../Icon';
import {isEmpty, validateEmail} from '@/utils/validations';

const EMAIL_ERROR = 'Invalid email';
const NAME_ERROR = 'Please add your name';

export interface IShareData {
  email: string;
  fromName: string;
}

export interface IShareProps {
  onSubmit?: (submitData: IShareData) => void;
  onCloseClick?: () => void;
  showConfirmation?: boolean;
  shareTitle?: string;
  shareMessage?: string;
  confirmationTitle?: string;
  confirmationMessage?: string;
}

const Share: FC<IShareProps> = ({
  shareTitle,
  shareMessage,
  confirmationTitle,
  confirmationMessage = 'Page shared',
  showConfirmation = false,
  onSubmit,
  onCloseClick,
}) => {
  const [email, setEmail] = useState('');
  const [fromName, setFromName] = useState('');
  const [emailError, setEmailError] = useState<string>();
  const [nameError, setNameError] = useState<string>();
  const [failedToShare, setFailedToShare] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isEmailValid = useMemo(() => validateEmail(email), [email]);
  const isNameValid = useMemo(() => !isEmpty(fromName), [fromName]);

  const handleSubmit = async () => {
    if (!isEmailValid) {
      setEmailError(EMAIL_ERROR);
    }
    if (!isNameValid) {
      setNameError(NAME_ERROR);
    }

    if (isEmailValid && isNameValid) {
      try {
        setFailedToShare(false);
        setIsSubmitting(true);
        await onSubmit?.({email, fromName});
        setIsSubmitting(false);
      } catch (e) {
        setFailedToShare(true);
        setIsSubmitting(false);
        console.error(e);
      }
    }
  };

  return (
    <div className='flex flex-col gap-y-5 sm:items-center sm:justify-center'>
      <div className='hidden size-14 items-center justify-center rounded-full bg-theme-lighter sm:flex'>
        <Icon
          size={30}
          strokeWidth={1.6}
          name={showConfirmation ? 'check' : 'user-round'}
          className='text-theme-primary'
        />
      </div>
      <div className='flex flex-col sm:items-center sm:justify-center sm:gap-y-2'>
        <Text as='headline-md' className='hidden sm:block'>
          {showConfirmation ? confirmationTitle : shareTitle}
        </Text>
        <Text as='title-md' className='py-2 sm:hidden' weight='extrabold'>
          {showConfirmation ? confirmationTitle : shareTitle}
        </Text>
        {showConfirmation ? (
          <Text as='body-md'>{confirmationMessage}</Text>
        ) : null}
        {!showConfirmation && shareMessage ? (
          <Text as='body-md'>{shareMessage}</Text>
        ) : null}
      </div>
      {!showConfirmation ? (
        <>
          <FormInput.Text
            id='share-email'
            type='email'
            label='Email'
            labelClassName='font-bold'
            placeholder='Coworker email'
            value={email}
            onChange={(val) => setEmail(val)}
            errorMessage={emailError}
            isValid={isEmailValid}
            disabled={isSubmitting}
            required
          />
          <FormInput.Text
            id='from-name'
            type='text'
            label='Who is sharing?'
            labelClassName='font-bold'
            placeholder='Type your name'
            value={fromName}
            onChange={(val) => setFromName(val)}
            errorMessage={nameError}
            isValid={isNameValid}
            disabled={isSubmitting}
            required
          />
          <Button
            id='submit-share'
            variant='alternative'
            stretched
            size='lg'
            disabled={isSubmitting}
            onClick={handleSubmit}>
            Share
          </Button>
          {failedToShare ? (
            <Text as='body-sm' variant='error'>
              Something happened while sharing, please try again
            </Text>
          ) : null}
        </>
      ) : (
        <Button
          id='close-share'
          variant='alternative'
          stretched
          size='lg'
          onClick={onCloseClick}>
          Close
        </Button>
      )}
    </div>
  );
};

export default Share;
